import React, { useMemo, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import DocumentMeta from 'react-document-meta';
import { ToastProvider } from 'react-toast-notifications';
import Customer from '../Components/Customer/Customer';
import Campaign from '../Components/Master/Campaign';
import CampaignPage from '../Components/Master/CampaignPage';
import CampaignImage from '../Components/Master/CampaignImage';
import CampaignPush from '../Components/Master/CampaignPush';
import CampaignIcon from '../Components/Master/CampaignIcon';
import CampaignPoints from '../Components/Master/CampaignPoints';
import Coupon from '../Components/Master/Coupon';
import CouponCode from '../Components/Master/CouponCode';
import CustomerDetail from '../Components/Customer/CustomerDetail';
import Ticket from '../Components/Master/Ticket';
import Setting from '../Components/Master/Setting';
import PaymentHistory from '../Components/Customer/PaymentHistory';
import User from '../Components/Master/User';
import CustomerRank from '../Components/Master/CustomerRank';
import CampaignEntry from '../Components/Campaign/CampaignEntry';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);
  const [siteName, setSiteName] = useState();
  const [meta, setMeta] = useState({});
  const [setting, setSetting] = useState(null);

  const getSiteName = async (siteId) => {
    const site = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/site/search/`, { site_id: siteId })).data;
    const setting = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${siteId}/`)).data;
    setSiteName(site.site_name);
    const temp = {
      link: {
        rel: {
          icon: `/${siteId}/favicon.ico`,
          "apple-touch-icon": `/${siteId}/logo192.png`,
          manifest: `/${siteId}/manifest.json`,
          stylesheet: `/${siteId}/style.css`,
        },
      },
      title: site ? site.site_name : '',
      description: site ? site.site_name : '',
    }
    setMeta(temp);
    setSetting(setting);
  }

  useEffect(() => {
    getSiteName(props.match.params.siteId);
  }, [props.match.params.siteId]);

  const _userProps = { user, userActions, siteName, setting, ...props };
  return (
    <div className='backyard-top'>
      <DocumentMeta {...meta} />
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider  placement="bottom-center">
          <Route render={() =>
            <UserOnly {..._userProps} >
              <Route exact path={`${props.match.path}`} >
                <Customer {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}customer/:id`} >
                <CustomerDetail {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaign`} >
                <Campaign {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignpush`} >
                <CampaignPush {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignicon/:id`} >
                <CampaignIcon {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignpoints`} >
                <CampaignPoints {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignpage`} >
                <CampaignPage {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignpage/:id`} >
                <CampaignPage {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignimage/:id`} >
                <CampaignImage {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}campaignentry`} >
                <CampaignEntry {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}coupon`} >
                <Coupon {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}couponcode/:id`} >
                <CouponCode {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}ticket`} >
                <Ticket {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}setting`} >
                <Setting {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}paymenthistory`} >
                <PaymentHistory {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}user`} >
                <User {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}customerrank`} >
                <CustomerRank {..._userProps} />
              </Route>
            </UserOnly>
          } />
          <Route render={() =>
            <GuestOnly {..._userProps} >
              <Route path={`${props.match.path}login`} render={() =>
                <Login {..._userProps} />
              } />
            </GuestOnly>
          } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

export default BackApp;