import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
// import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import Select from 'react-select';
import { useParams/*, generatePath*/ } from 'react-router';
import Common from '../Common/common';
import InfiniteScroll from "react-infinite-scroller";
const pageCount = 100;

const CampaignEntry = (props) => {
  const { siteId } = useParams();
  const { user, setting } = props;
  // const [customerData, setCustomerData] = useState(null);
  const [entryData, setEntryData] = useState(null);
  const [customerDetailMaster, setCustomerDetailMaster] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  const [condition, setCondition] = useState({});
  const [searchFlag, setSearchFlag] = useState(true);
  // const [errMsg, setErrMsg] = useState({ postalCode: '' });
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [lineTextData, setLineTextData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [page, setPage] = useState(0);

  const createCsvData = useCallback((data, master) => {
    if (campaignData) {
      const list = [];
      const head = [];
      head.push({ label: "キャンペーン名", key: "campaign_name" });
      head.push(...Common.inputList(setting, master).map(i => ({ label: i.entry_type_short_name, key: i.entry_type })));
      head.push({ label: "LINE_ID", key: "line_id" });
      data.forEach(c => {
        var customer = { line_id: c.t_customer.line_id };
        Common.inputList(setting, master).forEach(i => {
          customer[i.entry_type] = renderData(c.t_customer, i);
        });
        customer.campaign_name = campaignData.find(campaign => campaign.campaign_id === c.campaign_id) ? campaignData.find(campaign => campaign.campaign_id === c.campaign_id).campaign_name : '';
        list.push(customer);
      });

      setCsvData({ header: head, list: list });
    }
  }, [campaignData, setting]);

  const refreshCustomer = useCallback(async () => {
    if (setting && campaignData) {
      // useCallback対応のため検索ボタンを押したときのみ実行
      if (searchFlag) {
        const jwt = localStorage.getItem('jwt');
        const reg_params = { site_id: siteId };
        if (condition.campaign_id) {
          reg_params.campaign_id = condition.campaign_id.value;
        }

        // お客様詳細マスタ
        const master = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customerdetail/${siteId}`)).data;
        setCustomerDetailMaster(master);
        // エントリーデータ
        const entry_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        setEntryData(entry_data);
        createCsvData(entry_data, master);
        createLineTextData(entry_data);
      }
      setSearchFlag(false);
    }
  }, [searchFlag, campaignData, condition, siteId, createCsvData, setting]);

  const refreshMaster = useCallback(async () => {
    if (setting) {
      const jwt = localStorage.getItem('jwt');

      // キャンペーンマスタ
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/search/`, { site_id: siteId }, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setCampaignData(data);
    }
  }, [setting, siteId]);

  const handleHeaderClick = (column, detail) => {
    var _sortOrder = sortOrder;
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, detail, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, detail, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    setSortOrder(_sortOrder);
    var data = entryData.slice();
    data.sort((a, b) => sortData(a, b));
    setEntryData(data);
  }

  /* 退会処理 */
  const handleCancelClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`お客様ID[${data.customer_id}:${data.t_customer.card_id}]を退会済みにします。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      status: constClass.STATUS.CCL,
      payment_status: constClass.PAYMENT_STATUS.CCL, // 決済停止中
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/status/${data.customer_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshCustomer();
      setLockData(false);
    }
  }

  /* 退会取消処理 */
  const handleRegisterClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`お客様ID[${data.customer_id}:${data.t_customer.card_id}]の退会を取り消します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      status: constClass.STATUS.REG,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/status/${data.customer_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshCustomer();
      setLockData(false);
    }
  }

  const handleSelect = (column) => (data) => {
    setCondition({ ...condition, [column]: data });
  }

  const sortData = (a, b) => {
    if (sortOrder.length <= 0) { // 初期値はキャンペーンID→お客様ID
      return Number(a.campaign_id) - Number(b.campaign_id) || Number(a.customer_id) - Number(b.customer_id);
    }
    for (var i = 0; i < sortOrder.length; i++) {
      const item = sortOrder[i];
      // detailが不要な項目は先に判定する
      if (item.column === 'campaign_id') {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
        continue;
      }
      const detail = item.detail.find(() => true);
      if (detail.input_type === 'radio') {
        const aData = item.detail.find(d => d.entry_data === a.t_customer[item.column]) ? item.detail.find(d => d.entry_data === a.t_customer[item.column]).entry_seq : 0;
        const bData = item.detail.find(d => d.entry_data === b.t_customer[item.column]) ? item.detail.find(d => d.entry_data === b.t_customer[item.column]).entry_seq : 0;
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else if (detail.input_type === 'id') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else if (['date-y', 'date-m', 'date-d'].includes(detail.input_type)) {
        const start = detail.input_type === 'date-y' ? 0 : detail.input_type === 'date-m' ? 4 : 6;
        const end = detail.input_type === 'date-y' ? 4 : detail.input_type === 'date-m' ? 6 : 8;
        const aData = Number(a[detail.entry_type] ? a[detail.entry_type].slice(start, end) : 0);
        const bData = Number(b[detail.entry_type] ? b[detail.entry_type].slice(start, end) : 0);
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a.t_customer[item.column] < b.t_customer[item.column]) return -1;
          if (a.t_customer[item.column] > b.t_customer[item.column]) return 1;
        } else {
          if (b.t_customer[item.column] < a.t_customer[item.column]) return -1;
          if (b.t_customer[item.column] > a.t_customer[item.column]) return 1;
        }
      }
    }
    return 0;
  }

  const createLineTextData = (data) => {
    const list = data.map(c => [c.t_customer.line_id]);
    setLineTextData({ list: list });
  }

  const renderSortTh = (column, detail) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column, detail)} key={column}>
      {sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  const renderCancelButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1`}
        disabled={lockData}
        onClick={() => handleCancelClick(data)}>
        退会
      </button>
    )
  }

  const renderRegisterButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1 px-0`}
        disabled={lockData}
        onClick={() => handleRegisterClick(data)}>
        退会取消
      </button>
    )
  }

  const renderData = (data, entry) => {
    const detail = entry.detail_data.find(() => true);
    const record = entry.data ? Common.getCustomerDetailObject(data['entry_customer_detail']) : data;
    if (detail.input_type === 'radio') {
      return entry.detail_data.find(d => d.entry_data === record[entry.entry_type]) ? entry.detail_data.find(d => d.entry_data === record[entry.entry_type]).entry_name : '';
    } else if (detail.input_type === 'datetime') {
      return moment(record[entry.entry_type]).isValid() ? moment(record[entry.entry_type]).format("YYYY/MM/DD HH:mm:ss") : '';
    } else if (detail.input_type === 'date') {
      return Common.getDateObject(record[entry.entry_type]).str;
    } else if (detail.input_type === 'date-y') {
      // ymd付きが指定された場合はdetail.entry_typeから値を取得
      return Common.getDateObject(record[detail.entry_type]).year || '';
    } else if (detail.input_type === 'date-m') {
      return Common.getDateObject(record[detail.entry_type]).month || '';
    } else if (detail.input_type === 'date-d') {
      return Common.getDateObject(record[detail.entry_type]).date || '';
    } else {
      return record[entry.entry_type];
    }
  }

  useEffect(() => {
    refreshCustomer();
  }, [refreshCustomer]);

  useEffect(() => {
    refreshMaster();
  }, [refreshMaster]);

  return (
    <div className="container">
      <div className="row mx-0 my-2 px-1-env">
        <div className="col-11">
          <div className="row my-1">
            <div className="col-6">
              <div className="row">
                <div className="col-auto pr-0 text-center align-self-center">
                  <span>キャンペーン</span>
                </div>
                {campaignData &&
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
                      options={campaignData.map(c => ({ value: c.campaign_id, label: c.campaign_name }))}
                      isClearable={true}
                      // isMulti
                      closeMenuOnSelect={false}
                      value={condition.campaign_id}
                      onChange={handleSelect('campaign_id')} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 text-center">
          <button type="button"
            className={`btn btn-primary mx-1 w-100`}
            onClick={() => setSearchFlag(true)}>
            検索</button>
        </div>
      </div>
      {(entryData === null || campaignData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {(entryData !== null && campaignData !== null) && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-10 pl-0 text-left align-self-end">
              <div className="row mr-0">
                <div className="col-2 pr-2 align-self-center">
                  {entryData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          一覧表印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 align-self-center">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={"お客様一覧.csv"}>
                      一覧表CSV出力
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-2 align-self-center">
                  {lineTextData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={lineTextData.list} filename={"LINE通知用ユーザID一覧.csv"} enclosingCharacter={""}>
                      ユーザID出力
                    </CSVLink>
                  }
                </div>
              </div>
            </div>
            <div className="col-2 px-2 align-self-center text-right border h-100">
              <div className="row">
                <div className="col-auto py-1 px-2 bg-lightwhite h-100">一覧表件数</div>
                <div className="col py-1">{entryData.length.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <InfiniteScroll
                loadMore={(p) => setPage(Number(p) * pageCount)}    //項目を読み込む際に処理するコールバック関数 
                hasMore={(entryData.length > page)}         //読み込みを行うかどうかの判定
                loader={<div key={-1}>{entryData.length}件中 {page + pageCount}件まで表示中（スクロールで読み込み）</div>}       //読み込み最中に表示する項目
              >
                <table className="table table-bordered table-striped" height="1">
                  <thead className={`table-info`}>
                    <tr>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('campaign_id')}>
                        キャンペーン名
                      </th>
                      {Common.inputList(setting, customerDetailMaster).map(i =>
                        <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick(i.entry_type, i.detail_data)} key={i.entry_type}>
                          {i.entry_type_short_name}
                        </th>
                      )}
                      {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CANCEL')) &&
                        <th className="text-center align-middle">
                          処理
                        </th>
                      }
                    </tr>
                    <tr className="print-none">
                      {renderSortTh('campaign_id')}
                      {Common.inputList(setting, customerDetailMaster).map(i =>
                        renderSortTh(i.entry_type, i.detail_data)
                      )}
                      {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CANCEL')) && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {entryData.map((data, idx) => ( idx < (page + pageCount) && 
                      <tr key={data.campaign_id + '_' + data.customer_id}>
                        {/* キャンペーン名 */}
                        <td className="text-center align-middle p-1">
                          {campaignData.find(c => c.campaign_id === data.campaign_id) ? campaignData.find(c => c.campaign_id === data.campaign_id).campaign_name : ''}
                        </td>
                        {Common.inputList(setting, customerDetailMaster).map(i => {
                          return <td className="text-center align-middle p-1" key={i.entry_type + data.customer_id}>
                            {/* {i.entry_type === 'customer_id' && <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${data.customer_id}`}>{renderData(data, i)}</Link>} */}
                            {/* {i.entry_type !== 'customer_id' && renderData(data, i)} */}
                            {/* お客様詳細画面を作成するまではリンクを貼らない */}
                            {renderData(data.t_customer, i)}
                          </td>
                        })}
                        {/* 処理 */}
                        {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CANCEL')) &&
                          <td className="text-center align-middle p-1">
                            {data.t_customer.status === constClass.STATUS.REG && renderCancelButton(data)}
                            {data.t_customer.status === constClass.STATUS.CCL && renderRegisterButton(data)}
                          </td>
                        }
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CampaignEntry;