import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';

const Icon = (props) => {
  const { user } = props;
  const { id, siteId } = useParams();
  const [campaign, setCampaign] = useState({ campaign_id: null, campaign_name: null });
  const [iconData, setIconData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const [values, setValues] = useState({
    site_id: siteId,
    campaign_id: id,
    icon_id: '',
    icon_image_url: '',
    remarks: '',
    upd_date: null,
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');

    const campaignlist = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/search`, { site_id: siteId, campaign_id: id }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    if (campaignlist.length > 0) setCampaign(campaignlist[0]);

    const iconData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaignicon/${siteId}/${id}`)).data;
    setIconData(iconData);
    
  }, [siteId, id]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    var temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.icon_image_url != null) temp.uploadfile = `/${siteId}/${constClass.CAMPAIGN_IMAGE_DIR}/${id}/${data.icon_image_url}`;
    else temp.uploadfile = null;
    setValues(temp);
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      site_id: siteId,
      campaign_id: id,
      icon_id: '',
      icon_image_url: '',
      remarks: '',
      upd_date: null,
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null
    });
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    
    if ((data.uploadfiletype === null && data.icon_image_url === '')
    || (data.uploadfiletype === null && data.icon_image_url === data.deletefile)) {
      window.alert('アイコン画像を登録してください。');
      return;
    }
    var params = data;
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;

    let res;
    try {
      setLockData(true);
      if (params.icon_id === null || params.icon_id === "") {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignicon/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/campaignicon/${params.icon_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`アイコン[${data.icon_id}:${data.remarks}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: data.site_id,
      campaign_id: data.campaign_id,
      icon_id: data.icon_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignicon/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.icon_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setValues({
        ...values,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: values.icon_image_url
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.icon_image_url });
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>アイコンID</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="icon_id" value={values.icon_id} placeholder="新規" onChange={handleChange} readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>備考</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="remarks" value={values.remarks} onChange={handleChange} placeholder="備考を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">アイコン画像</label>
          </div>
          <div className="col-5 align-self-center">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="iconImage" id="iconImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <object className="icon-lg" id="uploadfileview" data={values.uploadfile} type={values.uploadfiletype}><img src={values.uploadfile} alt='uploadfile' /></object>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  キャンペーン
                </td>
                <td className="text-center align-middle">
                  アイコンID
                </td>
                <td className="text-center align-middle">
                  アイコン画像
                </td>
                <td className="text-center align-middle">
                  備考
                </td>
                <td className="text-center align-middle">
                  -
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {iconData.map((data, idx) => (
                <tr key={data.icon_id}>
                  {/* キャンペーン */}
                  <td className="text-center align-middle">
                    {campaign.campaign_name}
                  </td>
                  {/* クーポンID */}
                  <td className="text-center align-middle">
                    {data.icon_id}
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle ">
                    <div className="px-0 text-center">
                      <img className="icon-lg" src={`/${siteId}/${constClass.CAMPAIGN_IMAGE_DIR}${data.campaign_id}/${data.icon_image_url}`} onError={e => e.target.style.display = 'none'} alt="" />
                    </div>
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle">
                    {data.remarks}
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle">
                    {data.icon_id.toString() === "0" && "※未取得アイコン"}
                    {data.icon_id.toString() === "1" && "※標準アイコン"}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      {iconData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {iconData !== null && (renderList())}
      {renderInput()}
    </div>
  )
}

export default Icon;