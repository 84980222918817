import { createSlice } from '@reduxjs/toolkit';

// Stateの初期状態
const initialState = {
  isLoggedIn: false,
  isFetching: false,
  isPrepared: false,
  userId: null,
  userName: null,
  userShort: null,
  userClass: null,
  siteId: null,
  userToken: null,
};

const User = createSlice({
  name: "User",
  initialState: initialState,
  reducers: {
    login: (state, action) => ({
      isLoggedIn: true,
      isFetching: false,
      isPrepared: true,
      userId: action.payload.userId,
      userName: action.payload.userName,
      userShort: action.payload.userShort,
      userClass: action.payload.userClass,
      siteId: action.payload.siteId,
      userToken: action.payload.userToken,
    }),
    logout: (state) => ({
      isLoggedIn: false,
      isFetching: false,
      isPrepared: true,
      userId: null,
      userName: null,
      userShort: null,
      userClass: null,
      siteId: null,
      userToken: null,
    }),
    refresh: (state) => ({
      isLoggedIn: state.isLoggedIn,
      isFetching: state.isFetching,
      isPrepared: state.isPrepared,
      userId: state.userId,
      userName: state.userName,
      userShort: state.userShort,
      userClass: state.userClass,
      siteId: state.siteId,
      userToken: state.userToken,
    }),
    fetchLogin: (state) => ({
      isLoggedIn: state.isLoggedIn,
      isFetching: true,
      isPrepared: true,
      userId: state.userId,
      userName: state.userName,
      userShort: state.userShort,
      userClass: state.userClass,
      siteId: state.siteId,
      userToken: state.userToken,
    }),
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { login, logout, refresh, fetchLogin } = User.actions;

export default User;