import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { generatePath, useParams } from 'react-router';
import "react-datepicker/dist/react-datepicker.css"
import Modal from 'react-modal';
import util from 'util';
import TextareaAutosize from 'react-textarea-autosize';

Modal.setAppElement("#root");

const CampaignPage = (props) => {
  const { setting } = props;
  const history = useHistory();
  const { siteId, id } = useParams();
  const [campaignData, setCampaignData] = useState([]);
  const { addToast } = useToasts();
  const [campaignId, setCampaignId] = useState("");
  const [campaignPage, setCampaignPage] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [values, setValues] = useState({
    site_id: "",
    campaign_id: "",
    item_id: "",
    header: "",
    content: "",
    hidden_flag: "",
    upd_date: "",
  });

  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '37.5%',
      right: '37.5%',
      top: '15%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "75%",
      maxHeight: "75%",
      width: "25%",
      overflowX: "hidden",
      overflowY: "scroll",
    }
  };

  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const campaignData = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/search`, { site_id: siteId }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    campaignData.sort((a, b) => a.campaign_id < b.campaign_id ? -1 : 1);
    setCampaignData(campaignData);
    console.log(id);
    if (id) {
      const campaignPage = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpage/search/`, { site_id: siteId, campaign_id: id }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
      campaignPage.sort((a, b) => a.item_id < b.item_id ? -1 : 1);
      setCampaignPage(campaignPage);
      setCampaignId(id);
    }
  }, [siteId, id]);

  const refreshPageData = async (campaign_id) => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    const campaignPage = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpage/search/`, { site_id: siteId, campaign_id: campaign_id }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    campaignPage.sort((a, b) => a.item_id < b.item_id ? -1 : 1);
    setCampaignPage(campaignPage);
  };

  const refreshValues = async () => {
    reset();
    campaignPage.sort((a, b) => a.item_id < b.item_id ? -1 : 1);
    setCampaignPage(campaignPage);
  }

  const reset = async () => {
    setValues({
      site_id: "",
      campaign_id: "",
      item_id: "",
      header: "",
      content: "",
      hidden_flag: "",
      upd_date: "",
    });
  }

  const handleChange = (data) => (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }

  const handleCampaign = async (e) => {
    reset();
    const target = e.target;
    setCampaignId(target.value);
    if (target.value !== "") {
      const jwt = localStorage.getItem('jwt');
      const campaignPage = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpage/search/`, { site_id: siteId, campaign_id: target.value }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
      campaignPage.sort((a, b) => a.item_id < b.item_id ? -1 : 1);
      setCampaignPage(campaignPage);
    } else {
      setCampaignPage([])
    }
  }

  const handleUpdateClick = (data) => {
    setValues(data);
  }

  //削除ボタン押下時
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`画像[${data.item_id}:${data.header}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: data.site_id,
      campaign_id: data.campaign_id,
      item_id: data.item_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpage/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      refreshPageData(campaignId);
      refreshValues();
    }
  }

  const handlePreviewClick = async (data) => {
    setConfirm(true);
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.item_id) {
      window.alert('項目IDを入力してください。');
      return;
    }
    if (!window.confirm(`[${data.item_id}:${data.header}]を${values.campaign_id !== "" ? "更新" : "追加"}します。よろしいですか？`)) {
      return;
    }
    var params = {
      site_id: Number(props.match.params.siteId),
      campaign_id: Number(campaignId),
      item_id: Number(data.item_id),
      header: data.header,
      content: data.content,
      hidden_flag: data.hidden_flag ? data.hidden_flag : "0",
      upd_date: data.upd_date ? data.upd_date : new Date(),
    };
    let res;
    try {
      res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/campaignpage/update/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(`${values.campaign_id !== "" ? "更新" : "追加"}しました。`, { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      refreshPageData(campaignId);
      refreshValues();
    }
  }

  /* アイコン管理画面へ遷移 */
  const handleImageClick = () => {
    history.push(`${generatePath(`${props.match.path}campaignimage/${campaignId}`, { siteId: props.match.params.siteId })}`);
  }

  //追加・更新ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary w-50`}
        onClick={() => handleRegistClick(data)}>
        {values.campaign_id !== "" ? "更新" : "追加"}
      </button>
    )
  }

  //削除ボタン
  const deleteButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  //プレビューボタン
  const previewButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary w-75`}
        onClick={() => handlePreviewClick(data)}>
        プレビューを見る
      </button>
    )
  }

  const imageButton = () => {
    return (
      <button type="button"
        className={`btn btn-primary w-75`}
        onClick={() => handleImageClick()}>
        画像を登録する
      </button>
    )
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const resetButton = (data) => {
    return (
      <button
        className={`btn btn-primary w-50`}
        onClick={() => reset()}>
        リセット
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshData();
    }
    fetchData();
  }, [refreshData]);

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="row d-none"><div className="col-12">{util.inspect(campaignPage)}</div></div>
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle text-nowrap">
                  項目ID
                </td>
                <td className="text-center align-middle">
                  見出し
                </td>
                <td className="text-center align-middle">
                  内容
                </td>
                <td className="text-center align-middle">
                  非表示フラグ
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {campaignPage.map((data, idx) => (
                <tr key={data.item_id}>
                  {/* 項目ID */}
                  <td className="text-center align-middle">
                    {data.item_id}
                  </td>
                  {/* 見出し */}
                  <td className="text-center align-middle text-nowrap">
                    {data.header}
                  </td>
                  {/* 内容 */}
                  <td className="align-middle">
                    {data.content}
                  </td>
                  {/* 非表示フラグ */}
                  <td className="text-center align-middle">
                    {Number(data.hidden_flag) === Number(constClass.FLAG.ON) ? "ON" : "OFF"}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                    {deleteButton(data)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  const renderInput = () => {
    return (
      <div className="mx-1">
        {campaignId &&
          <div>
            <div className="row mb-3">
              <div className="col-2 text-center align-self-center">
                <span>項目ID</span>
              </div>
              <div className="col-2 text-center align-self-center">
                <input type="number" className="form-control" name="item_id" value={values.item_id || ""} onChange={handleChange(values)} disabled={values.campaign_id} placeholder="新規" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2 text-center align-self-center">
                <span>見出し</span>
              </div>
              <div className="col-7 text-center align-self-center">
                <input type="text" className="form-control" name="header" value={values.header || ""} onChange={handleChange(values)} placeholder="見出しを入力してください" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2 text-center align-self-center">
                <span>内容</span>
              </div>
              <div className="col-7 text-center align-self-center">
                <TextareaAutosize type="text-area" className="form-control" name="content" minRows={4} value={values.content || ""} onChange={handleChange(values)} placeholder="内容を入力してください" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2 text-center align-self-center">
                <span>非表示フラグ</span>
              </div>
              <div className="col-2 align-self-center">
                <input type="checkbox" className="form-control" name="hidden_flag" checked={Number(values.hidden_flag) === Number(constClass.FLAG.ON)} onChange={handleChange(values)} placeholder="入力してください" />
              </div>
              <div className="col px-0 align-self-center">
                <span>非表示にする</span>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-2 text-center align-self-center" />
              <div className="col-5 align-self-center">
                {renderRegistButton(values)}
              </div>
              <div className="col-5 align-self-center">
                {resetButton()}
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="mx-0">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーン</span>
          </div>
          <div className="col-4 text-center">
            <select className="custom-select w-100" aria-label="キャンペーン" value={campaignId || ''} onChange={handleCampaign}>
              <option value="">選択してください</option>
              {campaignData.map((item) =>
                <option key={item.campaign_id} value={item.campaign_id}>{item.campaign_name}</option>
              )}
            </select>
          </div>

          <div className="col-3 text-center align-self-center">
            {campaignId && previewButton(campaignPage)}
          </div>
          <div className="col-3 text-center align-self-center">
            {campaignId && imageButton(campaignPage)}
          </div>

        </div>
      </div>
    )
  }

  const renderConfirm = (data) => {
    return (
      <Modal isOpen={confirm} style={modalStyle} onRequestClose={() => setConfirm(false)}>
        {(setting && setting['HIDE_HEADER'] !== constClass.FLAG.ON) &&
          <header className="bg-light sticky-top">
            <div className="row mx-0">
              <div className={`col section text-center`}>
                <span>キャンペーン応募要項</span>
              </div>
            </div>
          </header>
        }
        <div className="bg-white max-vh-100">
          <div className="row mx-1 my-1">
            <div className="col py-0 px-0 align-self-center text-center">
              {<img className="w-100" src={`/${props.match.params.siteId}/${constClass.CAMPAIGN_IMAGE_DIR}${campaignId}/${constClass.CAMPAIGN_IMAGE_LIST.CONFIRM_TITLE}`}
                onError={e => e.target.style.display = 'none'}
                alt="title" />}
            </div>
          </div>
        <div className="row mx-1 px-1-env pt-2 pb-1 ">
          <h4>{campaignData.find(c => c.campaign_id === Number(campaignId)) ? campaignData.find(c => c.campaign_id === Number(campaignId)).campaign_name : ''}</h4>
        </div>
          <div className="row mx-1 pt-2 pb-1 ">
            <div className="col mx-0 px-1">
              {data && data.filter(x => Number(x.hidden_flag) !== Number(constClass.FLAG.ON)).map((data, idx) => (
                <div key={idx}>
                  <h5>{data.header}</h5>
                  <p className="pl-1" dangerouslySetInnerHTML={{ __html: data.content }}></p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer className="bg-light pr-f">
          <button className="btn btn-secondary w-75 m-1 mx-5" onClick={() => { setConfirm(false) }}>
            プレビューを閉じる
          </button>
        </footer>
      </Modal>
    )
  }

  return (
    <div className="container">
      {campaignData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {campaignData !== null && (renderHeader())}
      {renderList()}
      {renderInput()}
      {renderConfirm(campaignPage)}
    </div>
  )
}

export default CampaignPage;