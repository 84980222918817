import React, { useState, useEffect, useCallback } from 'react';
import Barcode from './Barcord';
//import CheckDigit from './CheckDigit';
import Logo from '../Images/logo.svg';
import axios from 'axios';

const MembershipPass = ({ siteId, customerId, lineId, liffAccessToken}) => {

    const [membershipId, setMembershipId,] = useState(1234567890128);
    const [customeName, setCustomerName] = useState(false);
    const [sumPoint, setSumPoint] = useState(0);

    //DBより取得予定
    const rank = "bronze";
    const expiration_date = "2023/05/18";

    //サイトID、顧客IDより会員IDと氏名情報を取得
    const refreshData = useCallback(async () => {
        try {
          //会員名を取得
          const customer_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, { site_id: siteId, line_id: liffAccessToken })).data;
          const customer_info = customer_data.find(c => c.line_id === lineId);
          setCustomerName(customer_info.customer_name);

          //会員IDを取得
          const card_info = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer_card/${siteId}/${customerId}`)).data;
          setMembershipId(card_info.card_id);

          //有効ポイントの合計を取得
          const point_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/available_sum/`, { site_id: siteId, customer_id: customerId, liff_access_token: liffAccessToken })).data;
          const point_info = point_data.find(c => c.customer_id === customerId);
          if(point_info !== undefined) setSumPoint(point_info.sum_point - point_info.sum_used_point);

        } catch (err) {
          console.log(err);
        }
      }, [siteId, customerId, lineId, liffAccessToken]);

    //会員IDを設定(会員IDが登録されていない場合はデフォルト値[0000000000000]を設定)
    const code = (membershipId ? membershipId : null);

    useEffect(() => {
        refreshData();
      }, [refreshData]);

    return (
        <div id="membershippass" className="px-0-env">
            <div className="row mx-0">
                <div className="col mx-3 my-1 p-0 text-left bg-white">
                    <div className={`mx-2 my-2 p-1 border border-dark rounded ` + (rank === "gold" ? 'goldpass' : (rank === "silver" ? 'silverpass' : 'bronzepass'))}>
                        <div className="pass-img">
                            <img src={Logo} alt="logo" />
                        </div>
                        {code !== null && (<Barcode code={code} />)}
                    </div>
                    <div className="col my-3 text-left bg-white">
                        <div className="card-header p-2 border">
                            <span><b>会員ID:</b></span>
                            <span className='pl-2'>{code}</span>
                        </div>
                        <div className="card-header p-2 border">
                            <span><b>氏名: </b></span>
                            <span className='pl-2'>{customeName}</span>
                        </div>
                        <div className="card-header p-2 border">
                            <span><b>会員ランク: </b></span>
                            {/*会員ランクで変更=>text-gold text-silver text-bronze*/}
                            <span className={`pl-2 fuchidori ` + (rank === "gold" ? 'text-gold' : (rank === "silver" ? 'text-silver' : 'text-bronze'))}>
                                <b>{(rank === "gold" ? 'ゴールド' : (rank === "silver" ? 'シルバー' : 'ブロンズ'))}</b>
                            </span>
                        </div>
                    </div>
                    <div className="col my-3 bg-white">
                        <div className="card-header p-2 text-left border">
                            <span><b>利用可能ポイント:</b></span>
                            <span className="float-right pr-2"><b>{sumPoint.toLocaleString()}</b> ポイント</span>
                        </div>

                        <div className="card-header p-2 border">
                            <span><b>ポイント有効期限: </b></span>
                            <span className="pl-2">{expiration_date}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MembershipPass;