import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';

const CustomerRank = (props) => {
  const { user } = props;
  const { siteId } = useParams();
  const [customerrankData, setCustomerRankData] = useState([]);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const [values, setValues] = useState({
    rank_id: null,
    rank_name: '',
    point_lower: '',
    point_upper: '',
  });

  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

      var reg_params = { site_id: siteId };
      const customerrankdata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customerrank/search`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      customerrankdata.sort((a, b) => a.point_lower - b.point_lower)
      setCustomerRankData(customerrankdata);
  }, [siteId]);


  /* 編集ボタン押下 */
  const handleUpdateClick = async (data) => {
    var temp = data;
    console.log(temp);
    setValues(temp);
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      rank_id: null,
      rank_name: '',
      point_lower: '',
      point_upper: '',
    });
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    console.log(data);
    if (!data.rank_name) {
      window.alert('ランク名称を入力してください。');
      return;
    }
    if (data.rank_name.length > 100) {
      window.alert('ランク名称は、100文字以内で入力してください。');
      return;
    }
    if (!String(data.point_lower)) {
      window.alert('付与ポイント（下限）を入力してください。');
      return;
    }
    if (!(String(data.point_lower)).match(/^[0-9]+$/)) {
      window.alert('付与ポイント（下限）は正の半角整数で入力してください。');
      return;
    }
    if (data.point_lower.length > 10) {
      window.alert('付与ポイント（下限）は10文字未満で入力してください。');
      return;
    }
    if (!data.point_upper) {
      window.alert('付与ポイント（上限）を入力してください。');
      return;
    }
    if (!(String(data.point_upper)).match(/^[0-9]+$/)) {
      window.alert('付与ポイント（上限）は正の半角整数で入力してください。');
      return;
    }
    if (data.point_upper.length > 10) {
      window.alert('付与ポイント（上限）は10文字未満で入力してください。');
      return;
    }
    if (data.point_upper <= data.point_lower) {
      window.alert('付与ポイント（下限）が付与ポイント（上限）に重複または超過しています、再入力してください。');
      return;
    }
    
    var err_msg = null;
    customerrankData.forEach((rankdata) => {
      if(rankdata.rank_id !== data.rank_id){  //編集対象の範囲はチェックしない
        if((rankdata.point_lower <= data.point_lower && data.point_lower <= rankdata.point_upper)   //下限の範囲の重複チェック
        || (rankdata.point_lower <= data.point_upper && data.point_upper <= rankdata.point_upper)){ //上限の範囲の重複チェック
          err_msg = `付与ポイントの上限または下限が重複しています。再入力してください`;
          return;
        }
      }
    })

    if (err_msg !== null) {
      window.alert(err_msg)
      return;
    }

    if(data.rank_id){
      if (!window.confirm(`ランクID【${data.rank_id}】：「${data.rank_name}」を更新します。よろしいですか？`)) {
        return;
      }
    }else{
      if (!window.confirm(`ランク名称「${data.rank_name}」を登録します。よろしいですか？`)) {
        return;
      }
    }

    var params = data;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;

    let res;
    try {
      setLockData(true);
      if(data.rank_id === null){
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customerrank/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/customerrank/${data.rank_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`ランクID[${data.rank_id}]:${data.rank_name}を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      rank_id: data.rank_id,
      rank_name: data.rank_name,
      point_lower: data.point_lower,
      point_upper: data.point_upper,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customerrank/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.rank_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }


  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>ランクID</span>
          </div>
          <div className="col-4 text-center align-self-center">
            <input type="text" className="form-control w-100" name="rank_id" defaultValue={values.rank_id} placeholder="新規" readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>ランク名称</span>
          </div>
          <div className="col-4 text-center align-self-center">
            <input type="text" className="form-control w-100" name="rank_name" value={values.rank_name} onChange={handleChange} placeholder="ランク名称を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>付与ポイント（下限）</span>
          </div>
          <div className="col-3 text-center align-self-center">
            <input type="text" className="form-control w-75" name="point_lower" value={values.point_lower} onChange={handleChange} placeholder="付与ポイント（下限）を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>付与ポイント（上限）</span>
          </div>
          <div className="col-3 text-center align-self-center">
            <input type="text" className="form-control w-75" name="point_upper" value={values.point_upper} onChange={handleChange} placeholder="付与ポイント（上限）を入力してください" />
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  ランクID
                </td>
                <td className="text-center align-middle">
                  ランク名称
                </td>
                <td className="text-center align-middle">
                  付与ポイント（下限）
                </td>
                <td className="text-center align-middle">
                  付与ポイント（上限）
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {customerrankData.map((data, idx) => (
                <tr key={idx}>
                  {/* ランクID */}
                  <td className="text-center align-middle">
                    {data.rank_id}
                  </td>
                  {/* ランク名称 */}
                  <td className="text-center align-middle">
                    {data.rank_name}
                  </td>
                  {/* 付与ポイント（下限） */}
                  <td className="text-center align-middle">
                    {data.point_lower}
                  </td>
                  {/* 付与ポイント（上限） */}
                  <td className="text-center align-middle">
                    {data.point_upper}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      {customerrankData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {customerrankData !== null && (renderList())}
      {renderInput()}
    </div>
  )
}

export default CustomerRank;