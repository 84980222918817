import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';
import TicketList from '../Liff/TicketList';
import LiffTicket from '../Liff/Ticket';
import moment from 'moment';

const Ticket = (props) => {
  const { user } = props;
  const { siteId } = useParams();
  const [ticketData, setTicketData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const { addToast } = useToasts();

  const [values, setValues] = useState({
    ticket_id: null,
    ticket_name: '',
    ticket_summary: '',
    ticket_detail: '',
    ticket_image_url: '',
    ticket_notes: '',
    hp_url: '',
    ticket_start_month: moment().format('YYYYMM'),
    ticket_end_month: '999912',
    ticket_times: 1,
    reset_flag: constClass.FLAG.ON,
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });

  const [pdfValues, setPdfValues] = useState({
    ticket_id: null,
    pdf_url: '',
    pdfuploadfile: null,
    pdfuploadfiletype: null,
    pdfdeletefile: null
  });

  const refreshData = useCallback(async () => {
    const ticketData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket/${siteId}`)).data;
    setTicketData(ticketData);
  }, [siteId]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    const temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.ticket_image_url != null) temp.uploadfile = `/${siteId}/${constClass.TICKET_IMAGE_DIR + data.ticket_image_url}`;
    else temp.uploadfile = null;
    temp.ticket_summary = temp.ticket_summary ? temp.ticket_summary : '';
    temp.ticket_detail = temp.ticket_detail ? temp.ticket_detail : '';
    temp.hp_url = temp.hp_url ? temp.hp_url : '';
    temp.ticket_notes = temp.ticket_notes ? temp.ticket_notes : '';
    temp.ticket_start_month = temp.ticket_start_month ? temp.ticket_start_month : moment().format('YYYYMM');
    temp.ticket_end_month = temp.ticket_end_month ? temp.ticket_end_month : '999912';
    temp.ticket_times = (temp.ticket_times !== null || temp.ticket_times !== undefined) ? temp.ticket_times : 1;
    temp.reset_flag = temp.reset_flag ? temp.reset_flag : constClass.FLAG.ON;

    setValues(temp);
    setPdfValues({
      ticket_id: null,
      pdf_url: '',
      pdfuploadfile: null,
      pdfuploadfiletype: null,
      pdfdeletefile: null
    });
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      ticket_id: null,
      ticket_name: '',
      ticket_summary: '',
      ticket_detail: '',
      ticket_image_url: '',
      ticket_notes: '',
      hp_url: '',
      ticket_start_month: moment().format('YYYYMM'),
      ticket_end_month: '999912',
      ticket_times: 1,
      reset_flag: constClass.FLAG.ON,
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null
    });
    setPdfValues({
      ticket_id: null,
      pdf_url: '',
      pdfuploadfile: null,
      pdfuploadfiletype: null,
      pdfdeletefile: null
    });
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.ticket_name) {
      window.alert('チケット名を入力してください。');
      return;
    }

    var params = data;
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;

    let res;
    try {
      setLockData(true);
      if (params.ticket_id === null || params.ticket_id === "") {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {

        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/ticket/${params.ticket_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`チケット[${data.ticket_id}:${data.ticket_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      ticket_id: data.ticket_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* PDFファイルアップロード */
  const handlePdfUploadClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.pdfuploadfiledata) {
      if (data.pdfdeletefile) {
        if (!window.confirm('PDFファイルを削除してもよろしいですか？')) {
          return;
        }
      } else {
        window.alert('ファイルを選択してください。');
        return;
      }
    }

    let res;
    try {
      setLockData(true);
      const form = new FormData();
      if (data.pdfuploadfiletype) form.append("uploadfile", data.pdfuploadfile);
      form.append("site_id", siteId);
      form.append("pdf_url", data.pdf_url);
      form.append("deletefile", data.pdfdeletefile);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/pdf_upload/${data.ticket_id}`, form, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'multipart/form-data',
        }
      });

    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* PDFボタン押下 */
  const handlePdfClick = (data) => {
    const temp = data;
    temp.pdfdeletefile = null;
    temp.pdfuploadfiletype = null;
    if (data.pdf_url != null) temp.pdfuploadfile = `/${siteId}/${constClass.TICKET_PDF_DIR + data.pdf_url}`;
    else temp.pdfuploadfile = null;

    setPdfValues(temp);
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const renderPdfButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handlePdfClick(data)}>
        PDF編集
      </button>
    )
  }

  const renderUploadButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || (!data.pdfuploadfiledata && !data.pdfdeletefile)}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handlePdfUploadClick(data)}>
        アップロード
      </button>
    )
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setValues({
        ...values,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: values.ticket_image_url
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.ticket_image_url });
  }

  const handlePdfChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setPdfValues({
        ...pdfValues,
        pdfuploadfile: value,
        pdfuploadfiletype: value.type,
        pdfdeletefile: pdfValues.pdf_url,
        pdfuploadfiledata: URL.createObjectURL(value),
      });
    }
    reader.readAsDataURL(value);
  }

  const handlePdfDeleteFile = () => {
    setPdfValues({ ...pdfValues, pdfuploadfile: null, pdfuploadfiletype: null, pdfdeletefile: pdfValues.pdf_url });
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>チケットID</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_id" defaultValue={values.ticket_id} placeholder="新規" readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>チケット名</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_name" value={values.ticket_name} onChange={handleChange} placeholder="チケット名を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_detail">チケット概要</label>
          </div>
          <div className="col-5 align-self-center">
            <input type="text" className="form-control" name="ticket_summary" id="ticket_summary" value={values.ticket_summary} placeholder="チケットの概要(カテゴリ)を入力してください" onChange={handleChange}></input>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_detail">チケット詳細</label>
          </div>
          <div className="col-5 align-self-center">
            <textarea className="form-control" rows="3" name="ticket_detail" id="ticket_detail" value={values.ticket_detail} onChange={handleChange}></textarea>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">チケット画像</label>
          </div>
          <div className="col-5 align-self-center">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="ticketImage" id="ticketImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <object id="uploadfileview" className="w-100" data={values.uploadfile} type={values.uploadfiletype}><img className="w-100" src={values.uploadfile} alt='uploadfile' /></object>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_notes">注意事項</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <textarea className="form-control" rows="3" name="ticket_notes" id="ticket_notes" value={values.ticket_notes} onChange={handleChange}></textarea>
          </div>
          <div className="col-5 text-left align-self-center">
            リンクを追加する場合は、<span className="text-danger">&lt;#</span><span className="text-info">（表示する文字）</span><span className="text-danger">$</span><span className="text-success">（リンク先URL）</span><span className="text-danger">#&gt;</span>と記載してください。<br />
            （例）<span className="text-danger">&lt;#</span><span className="text-info">予約はこちら</span><span className="text-danger">$</span><span className="text-success">https://example.com</span><span className="text-danger">#&gt;</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_start_month">利用開始年月</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_start_month" value={values.ticket_start_month} onChange={handleChange} placeholder="YYYYMM" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_end_month">利用終了年月</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_end_month" value={values.ticket_end_month} onChange={handleChange} placeholder="YYYYMM" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="hp_url">公式サイトURL</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="hp_url" value={values.hp_url} onChange={handleChange} placeholder="公式サイトへにリンクするURLを入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_times">利用回数</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="text" className="form-control" id="ticket_times" name="ticket_times" value={values.ticket_times} onChange={handleChange} />
          </div>
          <div className="col-5 text-left align-self-center">
            無制限に利用可能とする場合は「0」を入力してください
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="reset_flag">月度回数リセットフラグ</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="checkbox" className="form-control w-25" id="reset_flag" name="reset_flag" value="true" checked={values.reset_flag === constClass.FLAG.ON} onChange={handleChange}></input>
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderPdfButton(values)}
          </div>
        </div>
      </div>
    );
  }

  const renderPdf = () => {
    return (
      <div className="mx-1 mb-4 border">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">クーポンPDF</label>
          </div>
          <div className="col-5 align-self-center">
            {!(pdfValues.pdfuploadfile) && <input type="file" className="form-control-file" name="ticketPdf" id="ticketPdf" value="" onChange={handlePdfChangeFile} onClick={e => (e.target.value = null)} />}
            {pdfValues.pdfuploadfile && <object id="pdfuploadfileview" className="w-100" data={pdfValues.pdfuploadfiledata || pdfValues.pdfuploadfile} type={pdfValues.pdfuploadfiletype}>PDF</object>}
            {pdfValues.pdfuploadfile && <input type="button" className="form-controll" name="pdfdeletefile" id="pdfdeletefile" onClick={handlePdfDeleteFile} value="ファイル削除" />}
            {(!pdfValues.pdfuploadfiledata && pdfValues.pdfuploadfile) && <p>PDFのURL：<span>{process.env.REACT_APP_FRONTEND_URL}{pdfValues.pdfuploadfile}</span></p>}
          </div>
        </div>
        <div className="row mb-2 pb-2">
          <div className="col-3 text-center">
            {renderUploadButton(pdfValues)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 mb-1 p-0 text-right">
          <button type="button"
            disabled={lockData}
            className={`btn btn-primary mx-1 `}
            data-toggle="modal" data-target="#exampleModal"
            >
            プレビュー表示
          </button>
        </div>
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  チケットID
                </td>
                <td className="text-center align-middle">
                  チケット名
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {ticketData.map((data, idx) => (
                <tr key={data.ticket_id}>
                  {/* チケットID */}
                  <td className="text-center align-middle">
                    {data.ticket_id}
                  </td>
                  {/* チケット名 */}
                  <td className="text-center align-middle">
                    {data.ticket_name}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  const renderPreview = () => {
    return (
      <div className="modal" id="exampleModal">
        <div className="modal-dialog preview-modal">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setSelectData(null)}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {(ticketData && !selectData) &&
                <TicketList
                  ticket_list={ticketData}
                  ticketClick={(id) => () => setSelectData({...ticketData.find(t => t.ticket_id === id), t_ticket_use: []})}
                  siteId={siteId}
                />
              }
              {selectData &&
                <LiffTicket
                  ticket={selectData}
                  ticketClick={(id) => () => {}}
                  backClick={() => setSelectData(null)}
                  disabled={lockData}  
                  siteId={siteId}
                />
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setSelectData(null)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">

      {ticketData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {ticketData !== null && (renderList())}
      {renderInput()}
      {pdfValues.ticket_id && renderPdf()}
      {renderPreview()}
    </div>
  )
}

export default Ticket;