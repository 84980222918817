import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { generatePath, useParams } from 'react-router';
import CouponList from '../Liff/CouponList';

const Coupon = (props) => {
  const { user } = props;
  const { siteId } = useParams();
  const [couponData, setCouponData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const [values, setValues] = useState({
    coupon_id: null,
    coupon_name: '',
    coupon_summary: '',
    coupon_detail: '',
    coupon_image_url: '',
    app_url: '',
    app_text: '',
    detail_url: '',
    detail_text: '',
    copy_flag: constClass.FLAG.ON,
    hp_url: '',
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });

  const [pdfValues, setPdfValues] = useState({
    coupon_id: null,
    pdf_url: '',
    pdfuploadfile: null,
    pdfuploadfiletype: null,
    pdfdeletefile: null
  });

  const refreshData = useCallback(async () => {
    const couponData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/coupon/${siteId}`)).data;
    setCouponData(couponData);
  }, [siteId]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    const temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.coupon_image_url != null) temp.uploadfile = `/${siteId}/${constClass.COUPON_IMAGE_DIR + data.coupon_image_url}`;
    else temp.uploadfile = null;
    temp.coupon_summary = temp.coupon_summary ? temp.coupon_summary : '';
    temp.coupon_detail = temp.coupon_detail ? temp.coupon_detail : '';
    temp.app_url = temp.app_url ? temp.app_url : '';
    temp.app_text = temp.app_text ? temp.app_text : '';
    temp.detail_url = temp.detail_url ? temp.detail_url : '';
    temp.detail_text = temp.detail_text ? temp.detail_text : '';
    temp.hp_url = temp.hp_url ? temp.hp_url : '';

    setValues(temp);
    setPdfValues({
      coupon_id: null,
      pdf_url: '',
      pdfuploadfile: null,
      pdfuploadfiletype: null,
      pdfdeletefile: null
    });
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      coupon_id: null,
      coupon_name: '',
      coupon_summary: '',
      coupon_detail: '',
      coupon_image_url: '',
      app_url: '',
      app_text: '',
      detail_url: '',
      detail_text: '',
      copy_flag: constClass.FLAG.ON,
      hp_url: '',
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null
    });
    setPdfValues({
      coupon_id: null,
      pdf_url: '',
      pdfuploadfile: null,
      pdfuploadfiletype: null,
      pdfdeletefile: null
    });
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.coupon_name) {
      window.alert('クーポン名を入力してください。');
      return;
    }

    var params = data;
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;

    let res;
    try {
      setLockData(true);
      if (params.coupon_id === null || params.coupon_id === "") {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {

        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/coupon/${params.coupon_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`クーポン[${data.coupon_id}:${data.coupon_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      coupon_id: data.coupon_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* PDFファイルアップロード */
  const handlePdfUploadClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.pdfuploadfiledata) {
      if (data.pdfdeletefile) {
        if (!window.confirm('PDFファイルを削除してもよろしいですか？')) {
          return;
        }
      } else {
        window.alert('ファイルを選択してください。');
        return;
      }
    }

    let res;
    try {
      setLockData(true);
      const form = new FormData();
      if (data.pdfuploadfiletype) form.append("uploadfile", data.pdfuploadfile);
      form.append("site_id", siteId);
      form.append("pdf_url", data.pdf_url);
      form.append("deletefile", data.pdfdeletefile);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon/pdf_upload/${data.coupon_id}`, form, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'multipart/form-data',
        }
      });

    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* クーポンコード管理画面へ遷移 */
  const handleDetailClick = (coupon_id) => {
    history.push(`${generatePath(`${props.match.path}couponcode/${coupon_id}`, { siteId })}`);
  }

  /* PDFボタン押下 */
  const handlePdfClick = (data) => {
    const temp = data;
    temp.pdfdeletefile = null;
    temp.pdfuploadfiletype = null;
    if (data.pdf_url != null) temp.pdfuploadfile = `/${siteId}/${constClass.COUPON_PDF_DIR + data.pdf_url}`;
    else temp.pdfuploadfile = null;

    setPdfValues(temp);
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.coupon_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.coupon_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const renderDetailButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.coupon_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleDetailClick(data.coupon_id)}>
        クーポンコード
      </button>
    )
  }

  const renderPdfButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.coupon_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handlePdfClick(data)}>
        PDF編集
      </button>
    )
  }

  const renderUploadButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || (!data.pdfuploadfiledata && !data.pdfdeletefile)}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handlePdfUploadClick(data)}>
        アップロード
      </button>
    )
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setValues({
        ...values,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: values.coupon_image_url
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.coupon_image_url });
  }

  const handlePdfChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setPdfValues({
        ...pdfValues,
        pdfuploadfile: value,
        pdfuploadfiletype: value.type,
        pdfdeletefile: pdfValues.pdf_url,
        pdfuploadfiledata: URL.createObjectURL(value),
      });
    }
    reader.readAsDataURL(value);
  }

  const handlePdfDeleteFile = () => {
    setPdfValues({ ...pdfValues, pdfuploadfile: null, pdfuploadfiletype: null, pdfdeletefile: pdfValues.pdf_url });
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>クーポンID</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="coupon_id" defaultValue={values.coupon_id} placeholder="新規" readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>クーポン名</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="coupon_name" value={values.coupon_name} onChange={handleChange} placeholder="クーポン名を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="coupon_detail">クーポン概要</label>
          </div>
          <div className="col-5 align-self-center">
            <input type="text" className="form-control" name="coupon_summary" id="coupon_summary" value={values.coupon_summary} placeholder="クーポンの概要を入力してください" onChange={handleChange}></input>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="coupon_detail">クーポン詳細</label>
          </div>
          <div className="col-5 align-self-center">
            <textarea className="form-control" rows="3" name="coupon_detail" id="coupon_detail" value={values.coupon_detail} onChange={handleChange}></textarea>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">クーポン画像</label>
          </div>
          <div className="col-5 align-self-center">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="couponImage" id="couponImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <object id="uploadfileview" className="w-100" data={values.uploadfile} type={values.uploadfiletype}><img className="w-100" src={values.uploadfile} alt='uploadfile' /></object>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="app_url">アプリダウンロードURL</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="app_url" value={values.app_url} onChange={handleChange} placeholder="ダウンロードURLを入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="app_text">アプリダウンロードボタンテキスト</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="app_text" value={values.app_text} onChange={handleChange} placeholder="アプリをダウンロード" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="detail_url">クーポンコード利用方法URL</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="detail_url" value={values.detail_url} onChange={handleChange} placeholder="クーポンコード利用方法にリンクするURLを入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="detail_text">クーポンコード利用方法ボタンテキスト</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="detail_text" value={values.detail_text} onChange={handleChange} placeholder="クーポンコード利用方法" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="copy_flag">コピー表示フラグ</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="checkbox" className="form-control w-25" id="copy_flag" name="copy_flag" value="true" checked={values.copy_flag === constClass.FLAG.ON} onChange={handleChange}></input>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="hp_url">公式サイトURL</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="hp_url" value={values.hp_url} onChange={handleChange} placeholder="公式サイトへにリンクするURLを入力してください" />
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderPdfButton(values)}
          </div>
        </div>
      </div>
    );
  }

  const renderPdf = () => {
    return (
      <div className="mx-1 mb-4 border">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">クーポンPDF</label>
          </div>
          <div className="col-5 align-self-center">
            {!(pdfValues.pdfuploadfile) && <input type="file" className="form-control-file" name="couponPdf" id="couponPdf" value="" onChange={handlePdfChangeFile} onClick={e => (e.target.value = null)} />}
            {pdfValues.pdfuploadfile && <object id="pdfuploadfileview" className="w-100" data={pdfValues.pdfuploadfiledata || pdfValues.pdfuploadfile} type={pdfValues.pdfuploadfiletype}>PDF</object>}
            {pdfValues.pdfuploadfile && <input type="button" className="form-controll" name="pdfdeletefile" id="pdfdeletefile" onClick={handlePdfDeleteFile} value="ファイル削除" />}
            {(!pdfValues.pdfuploadfiledata && pdfValues.pdfuploadfile) && <p>PDFのURL：<span>{process.env.REACT_APP_FRONTEND_URL}{pdfValues.pdfuploadfile}</span></p>}
          </div>
        </div>
        <div className="row mb-2 pb-2">
          <div className="col-3 text-center">
            {renderUploadButton(pdfValues)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 mb-1 p-0 text-right">
          <button type="button"
            disabled={lockData}
            className={`btn btn-primary mx-1 `}
            data-toggle="modal" data-target="#exampleModal"
          >
            プレビュー表示
          </button>
        </div>
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  クーポンID
                </td>
                <td className="text-center align-middle">
                  クーポン名
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {couponData.map((data, idx) => (
                <tr key={data.coupon_id}>
                  {/* クーポンID */}
                  <td className="text-center align-middle">
                    {data.coupon_id}
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle">
                    {data.coupon_name}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                    {renderDetailButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }



  const renderPreview = () => {
    return (
      <div className="modal" id="exampleModal">
        <div className="modal-dialog preview-modal">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {couponData &&
                <CouponList
                  coupon_list={couponData}
                  coupon_codes={couponData.map(c => { return { coupon_id: c.coupon_id, coupon_code: '(クーポンコード)' } })}
                  couponClick={(id) => () => { }}
                  siteId={siteId}
                  popover_message={[]}
                />
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">

      {couponData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {couponData !== null && (renderList())}
      {renderInput()}
      {pdfValues.coupon_id && renderPdf()}
      {renderPreview()}
    </div>
  )
}

export default Coupon;