import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
//import util from 'util';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';
import { CSVLink } from "react-csv";
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const CampaignPoints = (props) => {
  const { user } = props;
  const { siteId } = useParams();
  const [condition, setCondition] = useState({ campaign_id: '', campaign_name: '', point_ym: null });
  const [customerData, setCustomerData] = useState([])
  const [customerCardData, setCustomerCardData] = useState([])
  const [campaignListData, setCampaignListData] = useState(null);
  const [campaignpointinputData, setCampaignPointInputData] = useState([]);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [csvData, setCsvData] = useState({ list: [], header: [] });
  const [importCsvData, setImportCsvData] = useState({
    list: [], header: [
      { label: "site_id", key: "site_id" },
      { label: "campaign_id", key: "campaign_id" },
      { label: "customer_id", key: "customer_id" },
      { label: "use_date", key: "use_date" },
      { label: "icon_id", key: "icon_id" },
      { label: "point", key: "point" },
      { label: "card_id1", key: "card_id1" },
      { label: "card_id2", key: "card_id2" },
    ]
  });

  const [values, setValues] = useState({
    campaign_id: null,
    campaign_name: null,
    point_date: null,
    file_name: null,
    data_count: null,
    uploadfile: null,
    uploadfile_name: null,
    newFlg: true,
    csvData: [],
  });
  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const campaigndata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/search`, { site_id: siteId }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setCampaignListData(campaigndata);
    if (condition.campaign_id !== '') {
      const campaignpointinputdata = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaignpointinput/${siteId}/${condition.campaign_id}`)).data;
      if (condition.point_ym !== null) {
        setCampaignPointInputData(campaignpointinputdata.filter(x => moment(x.point_date).format('YYYYMM') === moment(condition.point_ym).format('YYYYMM')));
      } else {
        setCampaignPointInputData(campaignpointinputdata);
      }
      var reg_params = { site_id: siteId };
      const customerdata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/list/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;

      const customercarddata = await customerdata.reduce((customercarddata, item) => {
        var p = [];
        item.t_customer_card.forEach(x => {
          if (x.card_id !== null && x.card_id !== '') {
            p.push({
              customer_id: item.customer_id,
              card_id: x.card_id
            })
          }
        });
        return customercarddata.concat(p);
      }, []);
      setCustomerData(customerdata);
      setCustomerCardData(customercarddata);
    } else {
      setCampaignPointInputData([]);
    }
    handleResetClick();
  }, [siteId, condition]);

  const createCsvData = async (point_date) => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const params = {
      site_id: siteId,
      campaign_id: condition.campaign_id,
      use_date: point_date
    };
    const campaignpointdata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpoints/search`, params, { headers: { Authorization: `Bearer ${jwt}` } })).data;

    var list = [];
    var head = [];
    head.push({ label: "サイトID", key: "site_id" });
    head.push({ label: "キャンペーンID", key: "campaign_id" });
    head.push({ label: "お客様ID", key: "customer_id" });
    head.push({ label: "利用日", key: "use_date" });
    head.push({ label: "アイコンID", key: "icon_id" });
    head.push({ label: "ポイント数", key: "point" });
    head.push({ label: "登録カードID1", key: "card_id1" });
    head.push({ label: "登録カードID2", key: "card_id2" });

    await Promise.all(campaignpointdata.map(item => {
      var c = {
        site_id: siteId,
        campaign_id: condition.campaign_id,
        customer_id: item.customer_id,
        use_date: moment(item.use_date).format("YYYY/MM/DD"),
        icon_id: item.icon_id,
        point: item.point,
        card_id1: item.t_customer_card.find(x => x.card_type === '1') ? item.t_customer_card.find(x => x.card_type === '1').card_id : '',
        card_id2: item.t_customer_card.find(x => x.card_type === '2') ? item.t_customer_card.find(x => x.card_type === '2').card_id : '',
      };
      list.push(c);
      return c;
    }));
    setCsvData({ header: head, list: list });

    var clist = [];
    var chead = [];
    chead.push({ label: "site_id", key: "site_id" });
    chead.push({ label: "campaign_id", key: "campaign_id" });
    chead.push({ label: "customer_id", key: "customer_id" });
    chead.push({ label: "use_date", key: "use_date" });
    chead.push({ label: "icon_id", key: "icon_id" });
    chead.push({ label: "point", key: "point" });
    chead.push({ label: "card_id1", key: "card_id1" });
    chead.push({ label: "card_id2", key: "card_id2" });
    const customer = customerData.filter(x => (x.t_entry.findIndex(e => e.campaign_id.toString() === condition.campaign_id.toString()) >= 0 && moment(x.t_entry.find(e => e.campaign_id.toString() === condition.campaign_id.toString()).entry_date).format('YYYYMMDD') <= moment(point_date).format('YYYYMMDD')));
    await Promise.all(customer.map(item => {
      var c = {
        site_id: siteId,
        campaign_id: condition.campaign_id,
        customer_id: item.customer_id,
        use_date: moment(point_date).format("YYYY/MM/DD"),
        icon_id: list.find(x => x.customer_id === item.customer_id) ? list.find(x => x.customer_id === item.customer_id).icon_id : "",
        point: list.find(x => x.customer_id === item.customer_id) ? list.find(x => x.customer_id === item.customer_id).point : "",
        card_id1: item.t_customer_card.find(x => x.card_type === '1') ? item.t_customer_card.find(x => x.card_type === '1').card_id : null,
        card_id2: item.t_customer_card.find(x => x.card_type === '2') ? item.t_customer_card.find(x => x.card_type === '2').card_id : null,
      };
      clist.push(c);
      return c;
    }));
    setImportCsvData({ header: chead, list: clist });
  }

  /* 編集ボタン押下 */
  const handleUpdateClick = async (data) => {
    var temp = data;
    temp.uploadfile = null;
    setValues(temp);
    await createCsvData(temp.point_date);
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      campaign_id: null,
      campaign_name: null,
      point_date: null,
      file_name: null,
      data_count: null,
      uploadfile: null,
      uploadfile_name: null,
      newFlg: true
    });
    setCsvData({ list: [], header: [] });
    setImportCsvData({
      list: [], header: [
        { label: "site_id", key: "site_id" },
        { label: "campaign_id", key: "campaign_id" },
        { label: "customer_id", key: "customer_id" },
        { label: "use_date", key: "use_date" },
        { label: "icon_id", key: "icon_id" },
        { label: "point", key: "point" },
        { label: "card_id1", key: "card_id1" },
        { label: "card_id2", key: "card_id2" },
      ]
    });
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.point_date) {
      window.alert('利用日付を指定してください。');
      return;
    }
    if (!data.uploadfile) {
      window.alert('ファイルをアップロードしてください。');
      return;
    }
    if (!window.confirm(`${moment(data.point_date).format('YYYY/MM/DD')}のポイントを反映します。よろしいですか？\n※反映済のポイントがある場合は削除されます`)) {
      return;
    }

    var params = {
      site_id: siteId,
      campaign_id: condition.campaign_id,
      use_date: moment(data.point_date).format('YYYY/MM/DD'),
      csvdata: data.uploadfile,
      file_name: data.uploadfile_name
    }

    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpoints/import`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`${moment(data.point_date).format('YYYY/MM/DD')}(${csvData.list.length}件)のポイント付与を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      campaign_id: data.campaign_id,
      use_date: data.point_date,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpoints/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.coupon_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.coupon_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const renderCsvButton = (data) => {
    return (
      <div className="col-3 text-center">
        <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={`ポイント一覧${moment(data.point_date).format('YYYYMMDD')}.csv`}>
          ポイント付与CSVダウンロード
        </CSVLink>
      </div>
    )
  }
  const renderCsv2Button = (data) => {
    return (
      <div className="col-3 text-center">
        <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={importCsvData.list} headers={importCsvData.header} filename={`ポイント取込${moment(data.point_date).format('YYYYMMDD')}.csv`} uFEFF={false}>
          ポイント取込用CSVダウンロード
        </CSVLink>
      </div>
    )
  }

  const handleChangePointdate = (date) => {
    setValues({ ...values, point_date: date ? date : null });
    if (campaignpointinputData.find(x => moment(x.point_date).format('YYYYMMDD') === moment(date).format('YYYYMMDD'))) {
      handleUpdateClick(campaignpointinputData.find(x => moment(x.point_date).format('YYYYMMDD') === moment(date).format('YYYYMMDD')));
    } else {
      createCsvData(date);
    }
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = (e) => {
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setLockData(true);
          if (results.data.some(x => (x.use_date !== null && moment(x.use_date).format('YYYYMMDD') !== moment(values.point_date).format('YYYYMMDD')))) {
            window.alert('ファイルの利用日付が一致しません');
          } else {
            const data = [];
            //対象利用者一覧
            const customer = customerData.filter(c => (c.t_entry.findIndex(e => e.campaign_id.toString() === condition.campaign_id.toString()) >= 0 && moment(c.t_entry.find(e => e.campaign_id.toString() === condition.campaign_id.toString()).entry_date).format('YYYYMMDD') <= moment(values.point_date).format('YYYYMMDD')));
            await Promise.all(results.data.map(async x => {
              if (x.use_date !== null) {
                if (!x.customer_id || x.customer_id === "" || x.customer_id === null) {
                  //カード番号からcustomer_idを取得
                  const customercard = customerCardData.find(c => [x.card_id1, x.card_id2].includes(c.card_id));
                  if (customer.findIndex(c => c.customer_id.toString() === customercard.customer_id.toString()) >= 0) {
                    data.push({
                      site_id: siteId,
                      campaign_id: condition.campaign_id,
                      customer_id: customercard.customer_id,
                      use_date: moment(values.point_date).format('YYYY/MM/DD'),
                      icon_id: x.icon_id,
                      point: x.point,
                      ins_name: user.userId,
                      ins_date: new Date(),
                      upd_name: user.userId,
                      upd_date: new Date(),
                    });
                  }
                } else {
                  if (customer.findIndex(c => c.customer_id.toString() === x.customer_id.toString()) >= 0) {
                    data.push({
                      site_id: siteId,
                      campaign_id: condition.campaign_id,
                      customer_id: x.customer_id,
                      use_date: moment(values.point_date).format('YYYY/MM/DD'),
                      icon_id: x.icon_id,
                      point: x.point,
                      ins_name: user.userId,
                      ins_date: new Date(),
                      upd_name: user.userId,
                      upd_date: new Date(),
                    });
                  }
                }
              }
            }));
            setValues({ ...values, uploadfile: data, uploadfile_name: value.name, data_count: data.length });
          }
          setLockData(false);
        },
      });
    }
    reader.readAsArrayBuffer(value);

  }
  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, data_count: 0 });
  }

  const handleCampaign = (e) => {
    const target = e.target;
    var tmp = { ...condition, campaign_id: target.value, campaign_name: campaignListData.find(x => x.campaign_id.toString() === target.value.toString()) ? campaignListData.find(x => x.campaign_id.toString() === target.value.toString()).campaign_name : "" };
    setCondition(tmp);
  }
  const handleCampaignYm = (date) => {
    var tmp = { ...condition, point_ym: date };
    setCondition(tmp);
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーン</span>
          </div>
          <div className="col-4 text-center align-self-center">
            <input type="text" className="form-control w-100" name="campaign_id" defaultValue={`${condition.campaign_id}:${condition.campaign_name}`} placeholder="新規" readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>利用日付</span>
          </div>
          <div className="col-5 text-left align-self-center">
            {!values.newFlg && moment(values.point_date).format('YYYY/MM/DD')}
            {values.newFlg &&
              <DatePicker className="custom-select w-50 text-left border-textbox"
                aria_labal="利用日付" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="pushDate" name="pushDate"
                selected={values.point_date ? values.point_date : null}
                onChange={date => handleChangePointdate(date)}
                minDate={condition.point_ym ? new Date(moment(condition.point_ym).startOf('month').format('YYYY/MM/DD')) : new Date(campaignListData.find(x => x.campaign_id.toString() === condition.campaign_id.toString()).campaign_start)}
                maxDate={condition.point_ym ? new Date(moment(condition.point_ym).endOf('month').format('YYYY/MM/DD')) : new Date(campaignListData.find(x => x.campaign_id.toString() === condition.campaign_id.toString()).campaign_end)}
                placeholderText="- 選択 -"
              />
            }
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="coupon_detail">キャンペーン参加人数</label>
          </div>
          <div className="col-5 align-self-center">
            {importCsvData.list.length}
          </div>
        </div>
        {!values.newFlg &&
          <div className="row mb-3">
            <div className="col-2 text-center align-self-center">
              <label htmlFor="coupon_detail">ポイント反映済件数</label>
            </div>
            <div className="col-5 align-self-center">
              {csvData.list.length}
            </div>
          </div>
        }
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="coupon_detail">ファイル</label>
          </div>
          <div className="col-3 align-self-center">
            {(!values.newFlg && !values.uploadfile) && values.file_name}
            {values.uploadfile && values.uploadfile_name}
          </div>
          <div className="col-5 align-self-center">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="pointFile" id="pointFile" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="coupon_detail">取込件数</label>
          </div>
          <div className="col-5 align-self-center">
            {values.data_count}
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
        </div>
        <div className="row mb-2 pb-4">
          {(csvData.list.length > 0 && values.point_date) && renderCsvButton(values)}
          {values.point_date && renderCsv2Button(values)}
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  キャンペーン
                </td>
                <td className="text-center align-middle">
                  利用日付
                </td>
                <td className="text-center align-middle">
                  取込ファイル名
                </td>
                <td className="text-center align-middle">
                  更新日
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {campaignpointinputData.map((data, idx) => (
                <tr key={idx}>
                  {/* クーポンID */}
                  <td className="text-center align-middle">
                    {data.campaign_id}:{campaignListData.find(x => x.campaign_id === data.campaign_id).campaign_name}
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle">
                    {moment(data.point_date).format("YYYY/MM/DD")}
                  </td>
                  <td className="text-center align-middle">
                    {data.file_name}
                  </td>
                  <td className="text-center align-middle">
                    {moment(data.upd_date).format('YYYY/MM/DD HH:mm')}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  const renderHeader = () => {
    return (
      <div className="mx-0">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーン</span>
          </div>
          <div className="col-5 text-center">
            <select className="custom-select w-100" aria-label="キャンペーン" value={condition.campaign_id || ''} onChange={handleCampaign}>
              <option value="">選択してください</option>
              {campaignListData.map((item) =>
                <option key={item.campaign_id} value={item.campaign_id}>{item.campaign_name}</option>
              )}
            </select>

          </div>
          <div className="col-1 text-center align-self-center">
            <span>年月</span>
          </div>
          <div className="col-3 text-center">
            <DatePicker className="custom-select w-50 text-left border-textbox"
              aria_labal="利用日付" locale="ja" dateFormat="yyyy/MM" monthsShown="1" id="selectYm" name="selectYm"
              selected={condition.point_ym ? condition.point_ym : null}
              minDate={condition.campaign_id ? new Date(campaignListData.find(x => x.campaign_id.toString() === condition.campaign_id.toString()).campaign_start) : null}
              maxDate={condition.campaign_id ? new Date(campaignListData.find(x => x.campaign_id.toString() === condition.campaign_id.toString()).campaign_end) : null}
              onChange={date => handleCampaignYm(date)}
              placeholderText="- 選択 -"
              showMonthYearPicker
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      {campaignpointinputData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {campaignListData !== null && renderHeader()}
      {campaignpointinputData !== null && (renderList())}
      {condition.campaign_id !== '' && renderInput()}
    </div>
  )
}

export default CampaignPoints;